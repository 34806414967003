import media from "./SiteMedia";
import React from 'react';

const content = [{
    cellName: "entre100",
    id: 0,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h1 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>College Mental Health</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>After graduation, I had a degree in computer science and a stong interest in psychology, but no idea what to do with either. I knew I didn't hold a passion for software engineering. But I didn't know what UX was yet. <br/><br/> I also had this nagging feeling, an urge to pursue something close to my heart. <span className="u-fw--semibold">I took a gap year after college to explore this desire.</span></React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "intro50",
    id: 1,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>Here's a <a className="u-text-link" href="https://medium.com/@dxautry/how-my-depression-became-my-career-ddf80c2cc5fd" target="_blank" rel="noopener noreferrer">short essay</a> on what mental healthcare means to me.</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>I didn't know what mental health was growing up. I didn't understand that my stress and rumination was actually a disorder. I didn't realize that my experiences could be explained. I didn't know that my pain was real.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "tldr50",
    id: 2,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>I wanted to create something to improve college mental health outcomes </React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Inspired by my own battle with mental illness, I felt this space held a lot of potential for change. Therapy taught me about myself &#8212; it inspired me to give back to the same community that changed my life.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "protoA100",
    id: 3,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xl u-mb--lg",
    img: media.mindbrush,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "Prototype for self-care reminders app"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "statA33",
    id: 4,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>75%</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Of lifetime mental illnesses develop before the age of 24 (Center for College Mental Health Penn State, 2015)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "statB33",
    id: 5,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>1700:1</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Average ratio of college students to therapists (Center for College Mental Health Penn State, 2015)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "statC33",
    id: 6,
    width: 33,
    blockClasses: "u-w--34 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>41%</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Students who suffer from a mental health issue (National Alliance on Mental Illness, 2016)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "college100",
    id: 7,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: "u-w--100",
    imgClasses: null,
    img: media.colleges,
    textClasses: "u-w--100 u-text-align--center",
    titleClasses: null,
    title: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "I collaborated with over 10 colleges and conducted a survey with 300+ students"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "ideation100",
    id: 8,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.think,
    textClasses: null,
    titleClasses: "u-w--75 u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>My hypothesis: More efficient clinics = better student outcomes</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>In my mind, reducing overhead and workload capacity for mental health clinics (especially repetitive tasks) would lead to more time that could be spent with students. <br/><br/>
    I conducted several rounds of research to understand the full landscape of college mental healthcare. I talked to over 40 college clinic directors and therapists, designed and conducted surveys for students, and interviewed research psychologists to understand the space.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "quotes50",
    id: 9,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>Quotes that stood out:</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
    "College clinics don't have an efficiency problem, but a capacity problem."
    <br/>
    &#8212; Ben Locke, Director of Center of Collegiate Mental Health
    <br/><br/>
    "Students need a primary prevention solution - something that catches the problem before it arises."
    <br/>
    &#8212; Peter LeViness, Clinic Director at University of Richmond Clinic
    <br/><br/>
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "insights50",
    id: 10,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>Insights from research</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
            &#8226; There is a clear need for primary prevention.
            <br/><br/>
            &#8226; Students have trouble identifying stress factors that can spiral into illness.
            <br/><br/>
            &#8226; Most clinics desire to spend more time in student outreach.
            <br/><br/>
            &#8226; <span className="u-fw--semibold">Making clinics more efficient would not have the most impact.</span>
            <br/><br/>
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "reframing100",
    id: 11,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>I learned very quickly that I was focusing on the wrong problem</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>My problem statement needed an adjustment. Although more efficient clinics would help, I learned that solving for primary prevention would have a far greater impact.<br/><br/>
    Clincis wanted students to learn self-care. It's more useful to invest in mental health early, long before they need to meet with a therapist. Primary prevention as this was called, is where my project needed to focus.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    name: "problemStatement100",
    id: 12,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-bg--alt u-flex-center--vertical",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.bulb,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h2>Revised problem statement: How might we motivate college students to manage and reduce stress, anxiety, and depression?</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>I revised my problem statement to focus on consumer, student-facing needs, as opposed to enterprise and clinic-facing needs before.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "solution50",
    id: 13,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>Design strategy</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
            &#8226; The solution must be where the student lives.
            <br/><br/>
            &#8226; It may not be in the form of an application.
            <br/><br/>
            &#8226; Messaging and campaigns seems like a very promising approach.
            <br/><br/>
            &#8226; Students must be able to trust the solution.
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "prototype50",
    id: 14,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>I spent the next several months prototyping different solutions, both digitally and outside of technology</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
    Several of my initiatives are shown below. Reach out for a full deep dive on the respective concepts.
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "protoB100",
    id: 15,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xl u-mb--lg",
    img: media.rechargeApp,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "Prototype website for a college wellness studio"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "protoC100",
    id: 16,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "row u-flex u-flex--stretch u-flex-center--horizontal u-flex-center--vertical img img-mock--md_alt u-mb--lg",
    img: media.rechargeFlyers,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "Flyers for a demo pop-up to test a wellness studio as an MVP"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "protoD100",
    id: 17,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xl u-mb--lg",
    img: media.jumpbox,
    textClasses: null,
    titleClasses: "u-w--75 u-color--body u-text-align--center u-m--0_auto",
    title: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "Concept for a self-care monthly subscription box aimed at combating the mental health stigma in adult men"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "results50",
    id: 18,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Results:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
      Won first prize in the University of Virginia's startup pitch competition for the social entrepreneurship track. I secured $7000 in grant funding and executed 4 pilots. I also became a paid mental health product design consultant to a local mental healthcare startup, helping kickstart their new market strategy and initial product MVP. <br/><br/>
      While I never fully brought my own product to market (starting a company on your own, from scratch, is really hard!), I learned so much about product design, business, and myself.
      <br/><br/>
      This gap year was the catalyst for my career in product design and fully cemented my passion for social impact. Afterwards, I joined The Washington Post and immersed myself in the world of design. 
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "learnings50",
    id: 19,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>More learnings that stuck with me:</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
        &#8226; There’s no weakness in asking for help.
        <br/><br/>
        &#8226; You’re often a mentor to someone, even if you don’t realize it.
        <br/><br/>
        &#8226; Surround yourself with people that push you.
        <br/><br/>
        &#8226; Networking is creating meaningful relationships to help each other move forward.
        <br/><br/>
        &#8226; There’s no weakness in placing personal well-being over work.
        <br/><br/>
        &#8226; Time management is a very underrated skill.
        <br/><br/>
        &#8226; You have to be confident in yourself before others can be confident in you.
        <br/><br/>
        &#8226; Forgive yourself. When you fail, when you make a mistake — there’s always something to learn from it.
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "press100",
    id: 20,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Press"],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", <React.Fragment>
                &#8226; <a className="u-text-link" href="https://news.virginia.edu/content/uva-alumnus-using-his-own-battle-depression-help-current-students" target="_blank" rel="noopener noreferrer">UVA Alumnus Using His Own Battle with Depression to Help Current Students</a>
                <br/><br/>
                &#8226; <a className="u-text-link" href="https://www.cavalierdaily.com/article/2019/01/u-va-alumnus-hopes-to-develop-app-to-combat-mental-illness" target="_blank" rel="noopener noreferrer">U.Va. alumnus hopes to develop app to combat mental illness
                </a>
                <br/><br/>
                &#8226; <a className="u-text-link" href="https://news.virginia.edu/content/hackcville-gives-graduating-entrepreneurs-way-bet-themselves" target="_blank" rel="noopener noreferrer">HackCville Gives Graduating Entrepreneurs a Way to Bet on Themselves
                </a>
                <br/><br/>
                &#8226; <a className="u-text-link" href="https://www.cvilletomorrow.org/hackcville-fellowship-looks-to-retain-uva-talent/" target="_blank" rel="noopener noreferrer">HackCville fellowship looks to retain UVa talent
                </a>
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "prev50",
    id: 23,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border--color",
    contentClasses: "u-mb--xl",
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Previous"],
    title1: [null, null],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", "The Washington Post"],
    hasLink: true,
    linkClasses: "u-arrow u-reverse u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "/washingtonpost"
  }];
  
  export default content;