import React, { useState, useEffect } from 'react';

const LiveTime = () => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
    const now = new Date();
    const options = { timeZone: 'America/New_York', hour12: true, hour: 'numeric', minute: '2-digit', second: '2-digit' };
    const easternTime = new Intl.DateTimeFormat('en-US', options).format(now).toLowerCase();
    setCurrentTime(easternTime);
    };

    // Update the time initially
    updateTime();

    // // Update the time every minute
    // const intervalId = setInterval(updateTime, 60000);

    // Update the time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means useEffect runs once on mount

  return (
    <React.Fragment>
        {currentTime}
    </React.Fragment>
  );
};

export default LiveTime;
