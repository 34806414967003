import media from "./SiteMedia";
import React from 'react';


const content = [{
    name: "header100",
    id: 0,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-pt--md u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: "u-w--90",
    imgClasses: "u-mb--lg",
    img: media.profile,
    textClasses: "",
    titleClasses: "u-h1 u-fw--light u-line-height--1_1",
    title: [null, <React.Fragment><h1><span className="">Hi, I'm Daniel</span><br/> a designer fascinated by the social impact space. This is my creative greenhouse.</h1></React.Fragment>],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    name: "google50",
    id: 1,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border-bottom u-border--color",
    contentClasses: "u-mb--xl",
    imgClasses: "u-mb--lg",
    img: media.googleSm,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: [null, <React.Fragment><h2>Right now, I design immersive news and election experiences at Google</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>2023 &#8212; News on Search (news queries), Civics on Search (global election queries).<br/> 2022 &#8212; 2023: Google News apps (iOS, Android, and news.google.com)</React.Fragment>],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "/google"
  }, {
    name: "wapo50",
    id: 2,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-bottom u-border--color",
    contentClasses: "u-mb--xl",
    imgClasses: "img-container img-container img-container--md u-mb--lg",
    img: media.wapo,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: [null, <React.Fragment><h2>Previously, I was a Senior Product Designer at The Washington Post</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Lead designer for the initial launch of The 7 (a daily news briefing), co-designer for 2020 United States election experiences. I also led design and research for a suite of consumer personalization and enterprise CMS experiences.</React.Fragment>],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "/washingtonpost"
  }, {
    name: "frontend100",
    id: 3,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-border-bottom u-border--color",
    contentClasses: "u-flex u-flex--row_reverse u-flex--space_between u-flex-center--horizontal",
    imgClasses: "u-w--33 u-flex u-flex-center--vertical u-flex-center--horizontal",
    img: media.tree1,
    textClasses: "u-w--60 u-flex u-flex--column u-flex--space_between",
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>I started my career as a frontend developer</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>I graduated with a degree in computer science from The University of Virginia. I also held a frontend-focused SWE internship at Goldman Sachs and worked part-time at a local web agency.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    name: "psych100",
    id: 4,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-border-bottom u-border--color",
    contentClasses: "u-flex u-flex--row_reverse u-flex--space_between u-flex-center--horizontal",
    imgClasses: "u-w--33 u-flex u-flex-center--vertical",
    img: media.tree2,
    textClasses: "u-w--60 u-flex u-flex--column u-flex--space_between",
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>But my true love is human psychology</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>I transitioned to design after I found a passion for mental health. I find time to code when I can &#8212; like this portfolio.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "entre50",
    id: 5,
    width: 33,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border-bottom u-border--color",
    contentClasses: "u-mb--xl",
    imgClasses: "u-mb--lg",
    img: media.lotusSm,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>I spent a year as a social entreprenuer, improving college mental health outcomes</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment><a className="u-text-link" href="https://medium.com/@dxautry/how-my-depression-became-my-career-ddf80c2cc5fd" target="_blank" rel="noopener noreferrer">Here's something I wrote</a> about how this project connected to my mental health experience in college. Full details in the case study below.</React.Fragment>],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "/mentalhealth"
  }, {
    cellName: "teach50",
    id: 6,
    width: 33,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-bottom u-border--color",
    contentClasses: "u-mb--xl",
    imgClasses: "u-mb--lg",
    img: media.sprout,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_2",
    title: [null, <React.Fragment><h2>I also teach and mentor aspiring designers and developers</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Shoot me a line if you'd like to connect on mentorship. Number one tip &#8212; <a className="u-text-link" href="https://www.reddit.com/r/UXDesign/comments/q3ldjb/why_is_there_such_a_disconnect_between/" target="_blank" rel="noopener noreferrer">stay off of Dribbble.</a></React.Fragment>],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "https://adplist.org/mentors/daniel-autry?utm_source=linkedin&utm_medium=social&utm_campaign=user_profileshare&utm_content=danielautry"
  }, {
    cellName: "interests100",
    id: 7,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-border-bottom u-border--color",
    contentClasses: "u-flex u-flex--row_reverse u-flex--space_between u-flex-center--horizontal",
    imgClasses: "u-w--33 u-flex u-flex-center--vertical u-flex-center--horizontal",
    img: media.flower,
    textClasses: "u-w--60",
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>In addition to Psychology, I’m passionate about News, Healthcare, Climate Change, and Education</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5  u-color--body", "This is where I find the intersection of technology and social impact the most fascinating, assuming there are the appropiate safeguards"],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "uxGarden100",
    id: 8,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-border-bottom u-border--color",
    contentClasses: "u-flex u-flex--row_reverse u-flex--space_between u-flex-center--horizontal",
    imgClasses: "u-w--33 u-flex u-flex-center--vertical u-flex-center--horizontal",
    img: media.project,
    textClasses: "u-w--60",
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>My current project is an open-source UX resource for aspiring designers (coming soon)</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "I'm a big believer in accessible quality education. I've had so many people who've helped me stay on my feet and I'd like to pay it forward."],
    hasLink: null,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "plant50",
    id: 9,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.houseplant,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><div>Outside of work, I’m a houseplant enthusiast and powerlifter</div></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>I hope to get a NASM training and nutrition certification when time allows (and learn how to keep a fiddle leaf alive)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "dj50",
    id: 10,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.volume,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h2>And an aspiring house DJ (80% serious)</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", "DJing became a pandemic hobby, and long, blended sets of house music relaxes my brain especially."],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "play33",
    id: 11,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.controller,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h3>Playing:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Elden Ring, Animal Crossing (late to the party)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "music33",
    id: 12,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.headphones,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h3>Listening to:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Fred Again, Mac Miller, Lofi beats, Khruangbin, The Daily</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "read33",
    id: 13,
    width: 33,
    blockClasses: "u-w--34 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.book,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h3>Reading:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Do comic books count?</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "contact66",
    id: 14,
    width: 66,
    blockClasses: "u-w--66 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border--color",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.hand,
    textClasses: null,
    titleClasses: "u-h2 u-line-height--1_25",
    title: [null, <React.Fragment><h3>Always down to collaborate when I have the time. Reach out for case studies. <br /><br/>All the best,<br />Daniel</h3></React.Fragment>],
    description: ["u-p2 u-line-height--1_5 u-color--body", <React.Fragment><br/>This site is hand-crafted and coded. Feel free to <a className="u-text-link" href="https://www.linkedin.com/in/daniel-autry/" target="_blank" rel="noopener noreferrer">visit my LinkedIn.</a> <br />Copyright © Daniel Autry. Icons and illustrations courtesy of Noun Project and respective designers (paid for).</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "photo33",
    id: 16,
    width: 33,
    blockClasses: "u-w--34 u-p--xxl bg-portrait",
    contentClasses: null,
    imgClasses: null,
    img: null,
    titleClasses: null,
    title: [null, null],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }];
  
  export default content;