import React from 'react';
import { Link } from 'react-router-dom';

export default function Block({ id, array }) {
  
    const getBlock = array.filter(block => 
      block.id === id
    );
  
    const renderBlock = getBlock.map(block =>
      <article className={block.blockClasses} key={id}>
          
          <div className={block.contentClasses}>
            {block.img ?
            <div className={block.imgClasses}>{block.img}</div>
            : null}
            <div className={block.textClasses}>
              <div className={block.titleClasses}>
                <span className={block.title[0]}>{block.title[1]}</span>
              </div>
              {block.description ? <p className={block.description[0]}>{block.description[1]}</p> : null}
            </div>
          </div>

          {block.hasLink ? <div className={`block-link ${block.linkDirection}`}><Link to={block.link} className={block.linkClasses}>&rarr;</Link></div> : null }

      </article>
    );
  
    return <React.Fragment>{renderBlock}</React.Fragment>;
  
  }