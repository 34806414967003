import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import { Home, Google, Wapo, Entre } from './Pages';
import { ThemeProvider } from './ThemeContext';

function ScrollToTop() {
  const currentPage = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage.pathname]);

  return null;
}

export default function Site () {
  return (
    <ThemeProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/google" element={<Google/>} />
          <Route path="/washingtonpost" element={<Wapo/>} />
          <Route path="/mentalhealth" element={<Entre/>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};
