import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles/styles.css";
import Site from "./components/Site";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVqiIxVJHKbUu-KdNRGdNoRRIpupHlyKM",
  authDomain: "portfolio-d54aa.firebaseapp.com",
  projectId: "portfolio-d54aa",
  storageBucket: "portfolio-d54aa.appspot.com",
  messagingSenderId: "551695825775",
  appId: "1:551695825775:web:7ba0bdbe08b5705be9069a",
  measurementId: "G-Z61CG5GR99"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Site />
  </StrictMode>
);