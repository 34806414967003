import React, { useContext } from 'react';
import Block from './Block';
import Nav from './Nav';
import { ThemeContext } from './ThemeContext';

export default function Page ({ contentArray }) {
  
  const rows = [];
  var currentRow = [];
  var currentRowWidth = 0;
  const { theme } = useContext(ThemeContext);
  
  contentArray.forEach((block) => {
    if (currentRowWidth + block.width > 100) {
      // Create a new row
      rows.push(currentRow);
      currentRow = [block];
      currentRowWidth = block.width;
    } else {
      // Add the current cell to the current row
      currentRow.push(block);
      currentRowWidth += block.width;
    }
  });

  // Add the last row even if it doesn't reach 100%
  if (currentRowWidth > 0) {
    rows.push(currentRow);
  }

  // Render the page
  const page = rows.map((row, key) => (
    <div key={key} className="row u-flex u-flex--row">
      {row.map((block) => (
        <Block key={block.id} id={block.id} array={contentArray} />
      ))}
    </div>
  ));

  // Return the page
  return (
    <React.Fragment>
      <div className={`container u-w--100 ${theme}`}>
        <div className="animation-wrapper">
          <Nav />
          {page}
        </div>
      </div>
    </React.Fragment>
  );

}
