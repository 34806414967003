import React from 'react';
import Page from './Page-template';
import home from '../content/Content-home';
import google from '../content/Content-google';
import wapo from '../content/Content-wapo';
import entre from '../content/Content-entre';

export function Home () {
    return (
        <React.Fragment>
          <Page contentArray={home} />
        </React.Fragment>
      );
  };

export function Google () {
  return (
      <React.Fragment>
        <Page contentArray={google} />
      </React.Fragment>
    );
  };

export function Wapo () {
  return (
      <React.Fragment>
        <Page contentArray={wapo} />
      </React.Fragment>
    );
  };

  export function Entre () {
    return (
        <React.Fragment>
          <Page contentArray={entre} />
        </React.Fragment>
      );
    };
