import media from "./SiteMedia";
import React from 'react';

const content = [{
    cellName: "wapo100",
    id: 0,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h1 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>The Washington Post</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Lead designer for the initial launch of <a className="u-text-link" href="https://www.washingtonpost.com/newsletters/the-7/" target="_blank" rel="noopener noreferrer">The 7</a> (a daily news briefing) and co-designer for 2020 United States election experiences. I also led design and research for a suite of consumer personalization and enterprise CMS experiences.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    name: "introA100",
    id: 1,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-bg--alt u-flex-center--vertical",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.the7Logo,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h2>The 7</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>The Washington Post wanted to launch a daily briefing for a younger demographic. Dubbed "The 7" for seven summarized news stories from 7am &#8212; 10am every day.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "the7ads100",
    id: 2,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl bg-the7--ads u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: null,
    title: [null, null],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "roleA33",
    id: 3,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Role:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Led end-to-end product design, research, and handoff. Led visual design for marketing campaign and American Express sponsorship.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "teamA33",
    id: 4,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Team:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Cross-functional partnership between Design, Product, Graphic Design, Engineering, Newsroom, Marketing, and Advertising.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "timeA33",
    id: 5,
    width: 33,
    blockClasses: "u-w--34 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Timeframe:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>April &#8212; September 2021</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "research50",
    id: 6,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Research & Ideation:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Led several rounds of unmoderated qualitative user research with a diverse set of participants across a younger demographic. <br/><br/>Goals included 1) establish a foundational understanding of news needs for "getting caught up quick", 2) understand behavior for audio preferences, and 3) further define general news behaviors for younger demographics.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "design50",
    id: 7,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Design strategy:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
    &#8226; <span className="u-fw--semibold">Keep it simple &#8212; </span>short, sweet, and to the point. Avoid redundant information and use everyday language. 
    <br/><br/>
    &#8226; <span className="u-fw--semibold">Emphasize legibility &#8212; </span>Use bolding to highlight key information and promote scannability. Distinguish numbers from text to improve flow of the page. Highlight visuals to increase appeal. 
    <br/><br/>
    &#8226; <span className="u-fw--semibold">Be convienent &#8212; </span>Showcase potential for routine-building. Use CTAs to call attention to other avenues of staying caught up, such as newsletters and in-app experiences. Keep readers within The Washington Post ecosystem.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "results100",
    id: 8,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Launch results:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Strong increase in newsletter signups and subscriber conversion, especially on Apple News. Strong engagement within The 7 app experiences, suggesting The 7 could be a useful tool for subscriber retention. <br/><br/>
    Next steps included fast-follows for visual refinements, further sponsorship integrations, and further scaling across Washington Post platforms. </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "the7A100",
    id: 9,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.the7A,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", "(1/2) The 7 article experience"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "the7B100",
    id: 10,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.the7B,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", "(2/2) The 7 article experience"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    name: "introB100",
    id: 11,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-bg--alt u-flex-center--vertical",
    contentClasses: null,
    imgClasses: "u-mb--lg",
    img: media.electionLogo,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h2>2020 United States general election</h2></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>2020 brought many unknowns. Our readers had trouble telling fact from fiction. Covid-19, social unrest, and a rise in misinformation made November unpredictable. Our job was to paint a clear, concise, and straightforward picture of what was happening.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "electionGIF100",
    id: 12,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xl u-mb--lg",
    img: media.electionGIF,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Homepage layouts based on predicted moments throughout the night</React.Fragment>],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "roleB33",
    id: 13,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Role:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Co-led end-to-end research and discovery. Led design for election realtime components and reader acquistion. Co-designer for homepage layouts and brand-new landing page.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "teamB33",
    id: 14,
    width: 33,
    blockClasses: "u-w--33 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Team:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Two designers (including myself), design manager, cross-functional partnerships with Product, Engineering, Graphic Design, Data Science, Newsroom, and Advertising.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "timeB33",
    id: 15,
    width: 33,
    blockClasses: "u-w--34 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Timeframe:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>February 2020 &#8212; January 2021</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "researchB50",
    id: 16,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Research & Ideation:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Methodology included task flows and analysis, card sorting, tree testing, and qualitive interviews. I then tweaked our existing reader personas to be election-information focused.<br/><br/> We found that election information was spread far too thin across our existing experiences, and readers had to jump between several pages as a result. We also learned much more about how COVID-19 complicated information-seeking journeys, especially as it came to mail-in voting and delayed results.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "designB50",
    id: 17,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Design strategy:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
    &#8226; <span className="u-fw--semibold">Proactive user education &#8212; </span>Communicate the variables exclusive to this election, prominently and clearly. Point to where things have changed from years past. Showcase predicted delays and potential mishaps ahead of time.
    <br/><br/>
    &#8226; <span className="u-fw--semibold">Realtime updates &#8212; </span>Always display the latest updates and communicate that the page is live. Promote reader acquistion experiences in moments of standby.
    <br/><br/>
    &#8226; <span className="u-fw--semibold">Consolidate where possible &#8212; </span>Group similar information together. Funnel users to a single destination, rather than fragment users across multiple destinations. Allow users to dive deeper when appropiate.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "results100",
    id: 18,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_25",
    title: [null, <React.Fragment><h3>Launch results:</h3></React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>
      &#8226; <span className="u-fw--semibold">Realtime components &#8212; </span>
      Increased State Page views by 31%, compared to 2018. Increased ecosystem recirculation from state pages by 45%, compared to 2018. In a post-election survey, 68% of respondents “completely understood” the status of election results. Another 30% “somewhat understood” the status. 
      <br/><br/>
      &#8226; <span className="u-fw--semibold">Election landing page &#8212; </span>
      Surpassed goal of non-subscriber page views by nearly 800%. Surpassed goal of overall page views by 513%. Surpassed goal of recirculation within the our ecosystem by nearly 900%.
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "r2wA100",
    id: 19,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl bg-r2w u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: null,
    title: [null, null],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "r2wB100",
    id: 20,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.electionR2W,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>Battleground states carousel component with realtime voting updates and predicted outcomes</React.Fragment>],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "electionLandingA100",
    id: 21,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.electionLandingA,
    textClasses: "u-text-align--center",
    titleClasses: null,
    title: [null, null],
    description: ["u-p2 u-mt--md u-m--0_auto u-line-height--1_5 u-color--body", <React.Fragment>(1/2) Election landing page &#8212; <br/>We needed a dedicated space for election coverage in case another event took precedence on the homepage (e.g. candidate health, natural distater, violence at polls)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "electionLandingB100",
    id: 22,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.electionLandingB,
    textClasses: "u-text-align--center",
    titleClasses: null,
    title: [null, null],
    description: ["u-p2 u-mt--md u-m--0_auto u-line-height--1_5 u-color--body", <React.Fragment>(2/2) Election landing page &#8212; <br/>We needed a dedicated space for election coverage in case another event took precedence on the homepage (e.g. candidate health, natural distater, violence at polls)</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  },{
    cellName: "prev50",
    id: 23,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border--color wapo-prev",
    contentClasses: "u-mb--xl",
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Previous"],
    title1: [null, null],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", "Google"],
    hasLink: true,
    linkClasses: "u-arrow u-reverse u-color--default u-border-span u-border--color",
    linkDirection: null,
    link: "/google"
  }, {
    cellName: "next50",
    id: 24,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between wapo-next",
    contentClasses: "u-flex u-flex--end u-mb--xl u-text-align--right",
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Next"],
    title1: [null, null],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", "College Mental Health"],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: "u-flex--end",
    link: "/mentalhealth"
  }];
  
  export default content;