import media from "./SiteMedia";
import React from 'react';

const content = [{
    cellName: "google100",
    id: 0,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h1 u-line-height--1_2",
    title: ["u-serif--1", <React.Fragment>Google</React.Fragment>],
    description: ["u-p1 u-mt--md u-line-height--1_5 u-color--body", <React.Fragment>2023 &#8212; News on Search <a className="u-text-link" href="https://www.google.com/search?q=government+shutdown&rlz=1C1CHBF_enUS985US985&oq=governme&gs_lcrp=EgZjaHJvbWUqCggBEAAYsQMYgAQyCggAEAAYsQMYgAQyCggBEAAYsQMYgAQyBggCEEUYOTIKCAMQABixAxiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIKCAcQABixAxiABDIHCAgQABiABDIHCAkQABiABNIBCDIyNjJqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8" target="_blank" rel="noopener noreferrer">(news queries)</a>, Civics on Search <a className="u-text-link" href="https://www.google.com/search?q=lok+sahba+how+to+vote&rlz=1C1CHBF_enUS985US985&oq=lok+sahba+how+to+vote&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIICAEQABgWGB4yCAgCEAAYFhgeMggIAxAAGBYYHjIICAQQABgWGB4yCAgFEAAYFhgeMggIBhAAGBYYHjIICAcQABgWGB4yCAgIEAAYFhgeMggICRAAGBYYHtIBCDQzMzhqMGo5qAIAsAIA&sourceid=chrome&ie=UTF-8" target="_blank" rel="noopener noreferrer">(global election queries)</a>.<br/> 2022 &#8212; 2023: Google News apps (iOS, Android, and <a className="u-text-link" href="https://news.google.com/home?hl=en-US&gl=US&ceid=US:en" target="_blank" rel="noopener noreferrer">news.google.com</a>).</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "interestPicker50",
    id: 2,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--md u-mb--lg",
    img: media.interestPicker,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", "Customization in Google News"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "followTab50",
    id: 3,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between u-border-right u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--md u-mb--lg",
    img: media.followTab,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", "Topic feed in Google News"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "election100",
    id: 4,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: "img img-mock img-mock--xxl u-mb--lg",
    img: media.electionResources,
    textClasses: null,
    titleClasses: "u-color--body u-text-align--center",
    title: ["u-p2 u-mt--md u-line-height--1_5 u-color--body", "Voter resources from government websites"],
    description: [null, null],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "press50",
    id: 5,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border-right u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Press"],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", <React.Fragment>
        &#8226; <a className="u-text-link" href="https://finance.yahoo.com/news/google-news-redesigned-following-tab-090518479.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAIsZ885x5EsM5Jb2r-Tu3MkdU9PSbXh3XaGu7UITvlXqtobhwT0bsU5g0t4cEmdOoxz7YJpGZo6w5y1cenm7Dg86VBGAAw8DOHS7qWBLO5MCrfwf907nQZRrIIQoVMyAfPU2xBmRUBGnyhGPlkDFXktBZyPPSMxWtnmNoQ4tQ8vU" target="_blank" rel="noopener noreferrer">Google News' redesigned Following tab now serves up interesting topics on your feed
        </a>
        <br/><br/>
        &#8226; <a className="u-text-link" href="https://blog.google/products/news/google-local-news-support/" target="_blank" rel="noopener noreferrer">New ways Google is helping local news
        </a>
        <br/><br/>
        &#8226; <a className="u-text-link" href="https://www.digitalinformationworld.com/2023/08/google-news-on-android-is-launching.html" target="_blank" rel="noopener noreferrer">Google News on Android is launching a revamped following feed that’s customized to users’ interests
        </a>
    </React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "contact50",
    id: 6,
    width: 50,
    blockClasses: "u-w--50 u-p--xxl u-flex u-flex--column u-border-bottom u-border--color",
    contentClasses: null,
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "daniel@danielautry.com"],
    title1: [null, null],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", <React.Fragment>Because the nature of this work is confidential, I'm limited in the amount of context I can share. Reach out for a full case study.</React.Fragment>],
    hasLink: false,
    linkClasses: null,
    linkDirection: null,
    link: null
  }, {
    cellName: "next50",
    id: 8,
    width: 100,
    blockClasses: "u-w--100 u-p--xxl u-flex u-flex--column u-flex-align--stretch u-flex--space_between",
    contentClasses: "u-flex u-flex--end u-mb--xl u-text-align--right",
    imgClasses: null,
    img: null,
    textClasses: null,
    titleClasses: "u-h2 u-fw--medium u-line-height--1_2",
    title: [null, "Next"],
    title1: [null, null],
    description: ["u-p1 u-mt--md u-line-height--1_3 u-color--body", "The Washington Post"],
    hasLink: true,
    linkClasses: "u-arrow u-color--default u-border-span u-border--color",
    linkDirection: "u-flex--end",
    link: "/washingtonpost"
  }];
  
  export default content;