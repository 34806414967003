import React, { useContext, useState, useEffect  } from 'react';
import Clock from './Clock';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';
import navMedia from '../content/NavMedia';

function Menu({ toggle, menuName }) {
  const { toggleTheme } = useContext(ThemeContext);
  
  return (
    <nav className="nav u-w--100 u-flex u-flex--row_nav u-flex--space_between u-flex-center--horizontal u-color--body">
        <div className="u-flex u-flex--row_nav u-flex--space_between u-flex-center--horizontal u-p1">
            <Link className="nav-link" to="/">
                <span className="u-color--body nav-link u-pr--sm">danielautry.com</span>
            </Link>
            <div className="u-hide--mobile">
              <span className="u-pr--sm">&#183;</span>
              <span className="u-pr--sm">Washington, DC</span>
              <span className="u-pr--sm">&#183;</span>
              <Clock /> et
            </div>
        </div> 
        <div className="u-nav--buttons u-flex u-flex--row u-flex--space_between u-flex-center--horizontal">
            <span className="u-p1 u-pr--sm nav-link" onClick={toggle}>{menuName}</span>
            <span className="u-pr--sm">&#183;</span>
            <button onClick={toggleTheme}>{navMedia.moon}</button>
        </div> 
      </nav>
  );
}

function Overlay({ isOpen, toggle }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (isOpen && event.key === 'Escape') {
        toggle();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, toggle]);

  return (
    <div className={`nav-overlay u-w--100 row u-flex u-flex--row ${isOpen ? 'nav-open' : ''}`}>
      <Menu toggle={toggle} menuName={"Close menu"} />
      <OverlayContent closeOverlay={toggle}/>
    </div>
  );
  }

function OverlayContent({ closeOverlay }) {

  const currentPage = useLocation();
  const handleClick = () => {
    closeOverlay();
  };


  return (
    <div className="u-nav--buttons u-flex u-flex--row u-w--100 u-h--100">
      <div className="nav-overlay-block u-w--34 u-p--xxl u-pt--sm u-flex u-flex--column">
          <div className="u-p2 u-caps u-mb--lg_alt u-color--opaque">Main</div>
          <Link className={`nav-link u-flex u-p1 u-mb--lg_alt ${currentPage.pathname === '/' && "u-flex"}`} to="/" onClick={handleClick}>Home {currentPage.pathname === '/' ? <React.Fragment><span className="u-current_page">{navMedia.location}</span></React.Fragment> : ""}</Link>
          <div className="nav-link u-p1 u-mb--lg_alt"><a className="nav-link" href="https://www.linkedin.com/in/daniel-autry/" target="_blank" rel="noopener noreferrer">Contact</a></div>
      </div>
      <div className="nav-overlay-block u-w--66 u-p--xxl u-pt--sm u-flex u-flex--column">
          <div className="u-p2 u-caps u-mb--lg_alt u-color--opaque">Work</div>
          <Link className={`nav-link u-flex u-p1 u-mb--lg_alt ${currentPage.pathname === '/google' && "u-flex"}`} to="/google" onClick={handleClick}>
            Google {currentPage.pathname === '/google' ? <React.Fragment><span className="u-current_page">{navMedia.location}</span></React.Fragment> : ""}
          </Link>
          <Link className={`nav-link u-flex u-p1 u-mb--lg_alt ${currentPage.pathname === '/washingtonpost' && "u-flex"}`} to="/washingtonpost" onClick={handleClick}>
            The Washington Post {currentPage.pathname === '/washingtonpost' ? <React.Fragment><span className="u-current_page">{navMedia.location}</span></React.Fragment> : ""}
          </Link>
          <Link className={`nav-link u-flex u-p1 u-mb--lg_alt ${currentPage.pathname === '/mentalhealth' && "u-flex"}`} to="/mentalhealth" onClick={handleClick}>
            College Mental Health {currentPage.pathname === '/mentalhealth' ? <React.Fragment><span className="u-current_page">{navMedia.location}</span></React.Fragment> : ""}
          </Link>
          <Link className="u-p1 u-mb--lg_alt u-color--inactive nav-inactive" to={null}>UX Garden &#183; Coming soon</Link>
      </div>
    </div>
  );
}

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
      setIsOpen(!isOpen);
      document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  return (
    <React.Fragment>
      <Menu toggle={toggleMenu} menuName={"Menu"}/>
      <Overlay className="u-w--100" isOpen={isOpen} toggle={toggleMenu} />
    </React.Fragment>
  );
}